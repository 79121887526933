import React from 'react';

import Layout from '../components/layout';
import Head from '../components/head';
import Paragraph from '../components/Paragraph';

import AppImg from '../Images/Forecast1.png';
import MobileAppImg from '../Images/Forecast2.png';

import {
    ForecastContainer,
    ProjectTable,
    BoldField,
    Image,
    TextContainer,
    MiddleContentContainer,
    LeftImage,
    RightContent,
    LinksContainer,
    CodeLink,
    SiteLink
} from '../Styles/Forecast.style';
 
const NotFound = () => { 
	return (
		<Layout>
			<Head title="Forecast" />
            <ForecastContainer>
                <TextContainer>
                    <h1>Forecast</h1>
                    <Paragraph>
                        Forecast is the first project I put together after learning the basics of React.
                        Building projects from scratch has always been my favorite way to improve my understanding
                        and expand my knowledge. In this project, I utilized OpenWeatherMap API to get the weather
                        data based on the user's location.
                    </Paragraph>
                </TextContainer>

              {/*  <ProjectTable>
                    <tr>
                        <th>Type</th>
                        <th>Stack</th>
                        <th>Code</th>
                        <th>Live</th>
                    </tr>
                    <tr>
                        <td>Personal</td>
                        <td>React</td>
                        <BoldField><a>Repository</a></BoldField>
                        <BoldField><a>View Site</a></BoldField>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Netlify</td>
                    </tr>
              </ProjectTable> */}

                <Image src={AppImg}/>

                <TextContainer>
                    <h3>Project Purpose and Goal</h3>
                    <Paragraph>
                        The main purpose of this project was to make a use of the skills
                        I've gained through the process of learning the basics of React
                        and also to improve my knowledge with APIs in general. 
                        Besides this, I spent a good amount of time planning and designing
                        the UI, in order to make it user friendly, easy and enjoyable to
                        use and get the weather status. 
                    </Paragraph>
                </TextContainer>

                <MiddleContentContainer>
                    <LeftImage src={MobileAppImg}>
                    </LeftImage>
                    <RightContent>
                        <h3>Problems and Thought Process</h3>
                        <Paragraph>
                            Everytime I start a new project, I normally start with creating a list
                            for all the issues my program needs to solve. For me, planning is a key,
                            this is why I always enjoy investing a good amount of time at the begining
                            of each project, to make things clear.<br />{' '}<br />
                            For this project, I needed to set the location based on the user input, import
                            weather data according to a specific location, calculate future dates and render
                            specific icons based on the weather data. In addition, for me it was a high priority
                            to make the weather application responsive and looks good and clean on all devices 
                            no matter what the screen size is.
                        </Paragraph>
                    </RightContent>
                </MiddleContentContainer>

                <TextContainer>
                    <h3>Future Improvements</h3>
                    <Paragraph>
                            I still have a few features I would love to
                            to add to this Forecast project. I belive I can
                            improve the way the user enter the location and
                            maybe make the app smarter by remembering the last 
                            used location so the user won't need to enter it everytime.
                            In addition, I would love to update the background image
                            according to the weather status so the application will give
                            the feeling of the weather the user is currently experiencing.
                            It will also be a great idea to give the user the ability to 
                            see multiple location weather status by adding more pages that 
                            the user can easily switch between them. 
                    </Paragraph>
                </TextContainer>
                
                <LinksContainer>
                    <CodeLink>
                        <a
                                title='React Weather API'
                                target='_blank'
                                rel='noreferrer'
                                href='https://github.com/shadencodes/weather-app'
                            >
                               
                                View Code <span>&#8250;</span>
                                
                            </a> 
                    </CodeLink>
                    <SiteLink>
                            <a
                                title='React Weather API'
                                target='_blank'
                                rel='noreferrer'
                                href='https://weatherapp-shadencodes.netlify.app'
                            >
                               
                                Live Site <span>&#8250;</span>
                                
                            </a>                        
                    </SiteLink>
                </LinksContainer>
            </ForecastContainer>
		</Layout>
	);
}

export default NotFound;